<template>
    <el-card shadow="never">
        <div class="search">
            <!-- 输入搜索关键字表单 -->
            <el-form :model="searchForm" ref="formRef" label-width="120px" label-position="right">
                <el-row :gutter="16">
                    <template v-for="item in searchConfig.formItems" :key="item.prop">
                        <!-- <el-col :xs="24" :sm="12" :md="8" :lg="8"> -->
                        <el-form-item :label="item.label" :prop="item.prop">
                            <template v-if="item.type === 'input'">
                                <el-input v-model="searchForm[item.prop]" :placeholder="item.placeholder"
                                    style="width: 220px;"></el-input>
                            </template>
                            <template v-if="item.type === 'date-picker'">
                                <el-date-picker v-model="searchForm[item.prop]" type="datetime"
                                    format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
                                    :placeholder="item.placeholder" style="width: 220px;" />
                            </template>
                            <template v-if="item.type === 'date-picker-day'">
                                <el-date-picker v-model="searchForm[item.prop]" type="date" format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD" :placeholder="item.placeholder" style="width: 220px;" />
                            </template>
                            <template v-if="item.type === 'select'">
                                <el-select v-model="searchForm[item.prop]" :placeholder="item.placeholder"
                                    style="width: 220px;">
                                    <template v-for="option in item.options" :key="option.value">
                                        <el-option :label="option.label" :value="option.value" />
                                    </template>
                                </el-select>
                            </template>
                        </el-form-item>
                        <!-- </el-col> -->
                    </template>
                    <!-- 重置和搜索按钮 -->
                    <div class="btns">
                        <el-button :icon="Refresh" @click="handleResetClick">重置</el-button>
                        <el-button type="primary" :icon="Search" @click="handleQueryClick">搜索</el-button>
                        <el-button v-if="isShowExport === true" type="success" plain :icon="DocumentChecked"
                            @click="hanldeExportExcel">导出excel</el-button>
                    </div>
                </el-row>
            </el-form>
        </div>
    </el-card>
</template>

<script setup>
import { reactive, ref, defineEmits, defineProps } from 'vue'
import { Refresh, Search, DocumentChecked } from '@element-plus/icons-vue'
const emit = defineEmits(['queryClick', 'resetClick', 'exportClick'])
const props = defineProps(['searchConfig', 'isShowExport'])

//定义form 数据
const initialForm = {}
for (const item of props.searchConfig.formItems) {
    initialForm[item.prop] = item.initialValue ?? ''
}
const searchForm = reactive(initialForm)

//重置表单
const formRef = ref()
function handleResetClick() {
    formRef.value?.resetFields()
    emit('resetClick', searchForm)
}

//查询表单
function handleQueryClick() {
    emit('queryClick', searchForm)
}

//导出Excel
function hanldeExportExcel() {
    emit('exportClick', searchForm)
}
</script>

<style lang="less" scoped>
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-top: 20px;

    .btns {
        margin-left: 30px;
        text-align: right;

    }
}
</style>